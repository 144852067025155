<template>
  <vuestic-widget>
    <div class="convainer">

      <div class="row">
        <div class="col-8 m-auto">
          <div class="form-group">
            <div class="input-group">
              <multiselect
                v-model="empresaSelecionada"
                placeholder="Empresa"
                label="nome"
                selectLabel="Selecione"
                :options="empresas"
                :multiple="false"
                @select="chegeEmpresa"
              >
                <span slot="noResult">Empresa não encontrada</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <el-transfer
          class="m-auto"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="Funcionários"
          v-model="acesso.funcionario"
          :data="funcionariosFiltrados"
          :props="props"
        >
        </el-transfer>
      </div>

      <div class="mt-4 row d-flex justify-content-end">
        <button
          class="btn btn-pale btn-micro mr-2"
          @click="$router.push({name: 'lista-checklist'})"
        >Voltar</button>
        <button
          class="btn btn-primary btn-micro"
          @click="submit()"
        >Confirmar</button>
      </div>
    </div>
  </vuestic-widget>

</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      idChecklist: null,
      empresaSelecionada: [],
      empresas: [],
      funcionarios: [],
      funcionariosFiltrados: [],
      acessos: [],
      acesso: {
        empresa: null,
        checklist: null,
        funcionario: [],
      },
      props: {
        label: 'nome',
        key: 'id',
      },
    };
  },
  created() {
    this.getEmpresas();
    this.getFuncionarios();
    this.getAcesso();
    this.idChecklist = Number(this.$router.history.current.params.checklist);
    this.acesso.checklist = this.idChecklist;
  },
  methods: {
    ...mapActions('acesso', [
      'getAcessos',
      'postAcesso',
      'putAcesso',
    ]),
    ...mapActions('funcionario', ['getFuncionariosList']),
    chegeEmpresa(val) {
      this.funcionariosFiltrados = this.funcionarios.filter(f => f.empresas.includes(val.id));
    },
    filterMethod(query, item) {
      return item.nome.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    submit() {
      if (this.acesso.id) {
        this.editAcesso();
      } else {
        this.cadastrarAcesso();
      }
    },
    async getAcesso() {
      try {
        const res = await this.getAcessos();
        this.acessos = res.filter(a => a.checklist === this.idChecklist);
      } catch (error) {
        console.error(error);
      }
    },
    getEmpresas() {
      this.$axios.get('/api/empresa.json').then((res) => {
        this.empresas = res.data;
      });
    },
    async getFuncionarios() {
      try {
        this.funcionarios = await this.getFuncionariosList();
      } catch (error) {
        console.error(error);
      }
    },
    async cadastrarAcesso() {
      try {
        const res = await this.postAcesso(this.acesso);
        this.acessos.push(res);
        this.empresaSelecionada = null;
      } catch (error) {
        console.error(error);
      }
    },
    async editAcesso() {
      try {
        const res = await this.putAcesso(this.acesso);
        this.empresaSelecionada = null;
        const index = this.acessos.findIndex(a => a.id === res.id);
        this.acessos.splice(index, 1, res);
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    empresaSelecionada(val) {
      const empresaFake = { id: -1 };
      this.chegeEmpresa(val !== null ? val : empresaFake);

      if (val) {
        const acesso = this.acessos.find(a => a.empresa === val.id);
        console.info(acesso);
        this.acesso = {
          id: acesso ? acesso.id : null,
          empresa: val.id,
          checklist: this.idChecklist,
          funcionario: acesso ? acesso.funcionario : [],
        };
      } else {
        this.acesso = {
          empresa: val.id,
          checklist: this.idChecklist,
          funcionario: [],
        };
      }
    },
  },
};
</script>

<style>
.el-transfer-panel {
	width: 300px !important;
}
</style>
